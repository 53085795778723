const groupPoolByToken = (pools) => {
  return Object
    .values(
      pools.reduce((pairs, pool) => {
        pairs[pool.inputToken.symbol] = pairs[pool.inputToken.symbol] || []
        pairs[pool.inputToken.symbol].push(pool)
        return pairs
      }, {})
    )
    .map((stakingPools) =>
      stakingPools.map((stakingPool, index) => ({
        stakingPool,
        rowspan: index === 0 ? stakingPools.length : 0,
      }))
    )
    .flat()
}

const groupPoolByExpiry = (pools) => {
  const byToken = groupPoolByToken(pools)
  const otPoolGroups = byToken.slice(0, byToken.length / 2)
  const ytPoolGroups = byToken.slice(byToken.length / 2)
  return ytPoolGroups.reduce((result, ytPool, groupIndex) => {
    result.push({ ...ytPool, rowspan: 2 })
    result.push({ ...otPoolGroups[groupIndex], rowspan: undefined })
    return result
  }, [])
}

export const getFormattedPools = (pools, combined) => combined
  ? groupPoolByExpiry(pools)
  : groupPoolByToken(pools)
