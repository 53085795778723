<template>
  <div class="container mx-auto py-8">
    <div class="space-y-10">
      <GasSaverNotice />
      <PendleStakingSection />
      <YtOtStakingSection />
      <hr class="!mt-20 block" />
      <OldOtStakingPoolSection />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import PendleStakingSection from '@/components/stakes/PendleStakingSection.vue'
  import YtOtStakingSection from '@/components/stakes/YtOtStakingSection.vue'
  import OldOtStakingPoolSection from '@/components/stakes/OldOtStakingPoolSection.vue'
  import GasSaverNotice from '@/components/GasSaverNotice.vue'

  export default Vue.extend({
    components: {
      PendleStakingSection,
      YtOtStakingSection,
      OldOtStakingPoolSection,
      GasSaverNotice,
    },
  })
</script>
