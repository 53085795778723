<template>
  <pp-section>
    <pp-btn
      variant="text"
      size="lg"
      class="font-semibold text-lg my-4"
      @click="expanded = !expanded"
    >
      {{ title }}
      <chevron-down-icon
        class="h-5 w-5 transform transition-transform ease-in-out duration-100 ml-2"
        :class="expanded ? 'rotate-180' : ''"
      ></chevron-down-icon>
    </pp-btn>
    <pp-slide-transition>
      <StakingPoolTable v-show="expanded" :pools="pools" :combined="combined" expired with-expiry />
    </pp-slide-transition>
  </pp-section>
</template>

<script>
  import StakingPoolTable from '@/components/tables/staking-pools/StakingPoolTable.vue'
  import ChevronDownIcon from '@/components/svg/outline/ChevronDownIcon.vue'

  export default {
    props: {
      title: { type: String, required: true },
      pools: { type: [Object, Array], required: true },
      combined: { type: Boolean, default: false }
    },
    components: {
      StakingPoolTable,
      ChevronDownIcon,
    },
    data() {
      return {
        expanded: false,
      }
    },
  }
</script>
