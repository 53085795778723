<template>
  <pp-section v-if="genericStakingPools.length > 0" header="Pendle Staking">
    <pp-card>
      <StakingPoolTable :pools="genericStakingPools" />
    </pp-card>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import StakingPool from '@/domains/entities/StakingPool'
  import StakingPoolTable from '@/components/tables/staking-pools/StakingPoolTable.vue'

  const genericStakingPools = StakingPool.query()
    .where('inputToken.name', 'PENDLE')
    .where('isActive', true)

  export default Vue.extend({
    components: {
      StakingPoolTable,
    },
    data() {
      return {
        genericStakingPools,
      }
    },
  })
</script>
