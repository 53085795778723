<template>
  <pp-section v-if="otStakingPools.length > 0" header="Deprecated OT Staking">
    <staking-pool-table :pools="otStakingPools" with-expiry></staking-pool-table>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import StakingPool from '@/domains/entities/StakingPool'
  import OtLpToken from '@/domains/entities/OtLpToken'
  import StakingPoolTable from '@/components/tables/staking-pools/StakingPoolTable.vue'

  const otStakingPools = StakingPool.query()
    .where(
      'inputToken.address',
      OtLpToken.query().map((token) => token.address)
    )
    .where('isActive', false)
    .where('stakableProtocol', (val) => val.includes('pendle'))

  export default Vue.extend({
    components: {
      StakingPoolTable,
    },
    data() {
      return {
        otStakingPools,
      }
    },
  })
</script>
