<template>
  <pp-tab-container>
    <pp-tab @click="toggleView" :active="!combined">
      <SplitTable v-if="combined" />
      <SplitTableActive v-else />
    </pp-tab>
    <pp-tab @click="toggleView" :active="combined">
      <CombinedTableActive v-if="combined" />
      <CombinedTable v-else />
    </pp-tab>
  </pp-tab-container>
</template>

<script>
  import Vue from 'vue'
  import CombinedTableActive from '@/components/svg/CombinedTableActive.vue'
  import CombinedTable from '@/components/svg/CombinedTable.vue'
  import SplitTableActive from '@/components/svg/SplitTableActive.vue';
  import SplitTable from '@/components/svg/SplitTable.vue';

  export default Vue.extend({
    props: {
      combined: { type: Boolean, default: false },
    },
    components: {
      CombinedTableActive,
      CombinedTable,
      SplitTableActive,
      SplitTable,
    },
    methods: {
      toggleView() {
        this.$emit("toggle");
      },
    },
})
</script>
