<template>
  <pp-tr
    :to="{ name: 'StakeShow', params: { inputTokenAddress: pool.inputToken.address } }"
    class="outline-none"
  >
    <pp-td v-if="combined || rowspan" :rowspan="!combined && rowspan" :class="border">
      <pp-token-preview :token="pool.inputToken" subtitle="protocol"></pp-token-preview>
      <span v-if="pool.disabled" class="align-top font-bold ml-2">(OLD)</span>
    </pp-td>

    <pp-td
      v-if="withExpiry && (!combined || rowspan)"
      :rowspan="combined && rowspan"
      :class="border"
      class="!px-2 align-middle"
      align="right"
    >
      <div>
        <pp-formatted-date
          :value="pool.inputToken.expiresAt"
          :data-test="`${pool.address.toLowerCase()}-expiry`"
        ></pp-formatted-date>
      </div>
      <pp-market-status-indicator :address="pool.inputToken.address"></pp-market-status-indicator>
    </pp-td>

    <pp-td :class="border" align="right">
      <div v-if="pool.disabled">-</div>

      <div v-else-if="hasNoStakePool || hasDeprecatedStakePool">
        <pp-formatted-price
          v-if="marketDetailsState.is('resolved')"
          :currency="marketDetailsState.response.otherDetails.liquidity.currency"
          :value="marketDetailsState.response.otherDetails.liquidity.amount"
        ></pp-formatted-price>

        <pp-skeleton-loader
          v-else-if="marketDetailsState.is('loading')"
          class="w-16 inline-block"
        ></pp-skeleton-loader>

        <span v-else-if="marketDetailsState.is('rejected')" class="text-p-red-600">Fail to load</span>
      </div>

      <div v-else>
        <pp-formatted-price
          v-if="totalStakedState.is('resolved')"
          :currency="totalStakedState.response.valuation.currency"
          :value="totalStakedState.response.valuation.amount"
          :data-test="`${pool.address.toLowerCase()}-total-staked`"
        ></pp-formatted-price>

        <pp-skeleton-loader
          v-else-if="totalStakedState.is('loading')"
          class="w-16 inline-block"
        ></pp-skeleton-loader>

        <span v-else-if="totalStakedState.is('rejected')" class="text-p-red-600">Fail to load</span>
      </div>
    </pp-td>

    <pp-td :class="border" align="right">
      <span v-if="aprState.is('resolved')">
        <div v-if="pool.disabled">0.000%</div>

        <div v-else>
          <pp-formatted-number
            :value="totalApr"
            :data-test="`${pool.address.toLowerCase()}-lp-apr`"
          ></pp-formatted-number>
          %
        </div>
      </span>

      <pp-skeleton-loader
        v-else-if="aprState.is('loading')"
        class="w-16 inline-block"
      ></pp-skeleton-loader>

      <span v-else-if="aprState.is('rejected')" class="text-p-red-600">Fail to load</span>
    </pp-td>

    <pp-td :class="border" align="right">
      <div class="flex flex-nowrap justify-end space-x-2">
        <pp-btn
          :data-test="`${action.text}-button`"
          v-for="action in actions"
          :key="action.text"
          :tag="action.disabled || action.loading ? 'button' : 'router-link'"
          :disabled="action.disabled"
          :loading="action.loading"
          :variant="action.variant"
          :to="action.to"
          class="h-8 min-w-[90px]"
        >
          {{ action.text }}
        </pp-btn>
      </div>
    </pp-td>
  </pp-tr>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import PendleMarket from '@/domains/entities/PendleMarket'
  import MerkleDistributor from '@/domains/entities/MerkleDistributor'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    props: {
      pool: { type: Object, required: true },
      rowspan: { type: Number, default: undefined },
      withExpiry: { type: Boolean, required: true },
      combined: { type: Boolean },
    },
    data() {
      return {
        aprState: createState({ response: [] }),
        marketDetailsState: createState({
          response: {
            otherDetails: {
              liquidity: {
                currency: 'USD',
                amount: '0',
              },
            },
          },
        }),
        userStaked: createState(),
        totalStakedState: createState({
          response: {
            amount: null,
            valuation: {
              amount: '0',
              currency: 'USD',
            },
          },
        }),
      }
    },
    methods: {
      fetchSwapFeeApr() {
        if (this.isPendle) return Promise.resolve([{ origin: 'Swap fee', apr: '0' }])

        return new Promise((resolve, reject) => {
          this.market
            .contract(this.$store.getters['wallet/identity'])
            .getSwapFeeApr()
            .then((response) => {
              resolve([{ origin: 'Swap fee', apr: response }])
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      fetchStakingPoolRewardAprs() {
        return this.pool
          .contract(this.$store.getters['wallet/identity'])
          .rewardAprs()
      },
      fetchMerkleDistributorRewardApr() {
        const merkleDistributor = new MerkleDistributor({ chainId: 1 })
        return merkleDistributor
          .contract(this.$store.getters['wallet/identity'])
          .rewardAPR(this.market.token)
      },
      fetchPoolRewardApr() {
        return this.isMainnet && !this.isPendle
          ? this.fetchMerkleDistributorRewardApr()
          : this.fetchStakingPoolRewardAprs()
      },
      fetchAprs() {
        new PromiseHandler(
          () => Promise.all([
            this.fetchPoolRewardApr(),
            this.fetchSwapFeeApr(),
          ]),
          this.aprState
        ).execute()
      },
      fetchMarketDetails() {
        new PromiseHandler(
          () => this.market?.contract(this.$store.getters['wallet/identity']).readMarketDetails(),
          this.marketDetailsState
        ).execute()
      },
      fetchTotalStaked() {
        new PromiseHandler(
          () => this.pool.contract(this.$store.getters['wallet/identity']).getTotalStaked(),
          this.totalStakedState
        ).execute()
      },
      fetchUserStaked() {
        new PromiseHandler(
          () =>
            this.pool
              .contract(this.$store.getters['wallet/identity'])
              .balanceOf(this.$store.state.wallet.address),
          this.userStaked
        ).execute()
      },
    },
    computed: {
      isPendle() {
        return Boolean(!this.market)
      },
      market() {
        return PendleMarket.query().find(this.pool.inputToken.address)
      },
      rewards() {
        return this.aprState.response
          .flat()
          .map((reward) => ({ ...reward, apr: 100 * reward.apr }))
          .filter((reward) => reward.apr > 0)
      },
      totalApr() {
        return this.rewards.reduce((a, b) => a + b.apr, 0)
      },
      hasNoStakePool() {
        return this.pool.address === '0x0'
      },
      hasDeprecatedStakePool() {
        return !this.hasNoStakePool && this.isMainnet && this.withExpiry
      },
      isMainnet() {
        return sdkChainId === 1
      },
      actions() {
        const noUserStaked = this.userStaked.response?.formattedAmount() == 0
        return [
          this.withExpiry
            ? {
                to: {
                  name: 'Liquidity',
                  params: {
                    inputTokenAddress: this.pool.inputToken.address,
                  },
                },
                text: 'Liquidity',
                variant: 'outline',
                disabled: false,
              }
            : undefined,
          {
            to: {
              name: 'StakeIndex',
              params: {
                inputTokenAddress: this.pool.inputToken.address,
              },
            },
            text: this.hasDeprecatedStakePool || this.hasNoStakePool ? 'Unstake' : 'Stake',
            variant: 'fill',
            disabled: this.hasNoStakePool || this.hasDeprecatedStakePool && noUserStaked,
            loading: this.withExpiry && !this.hasNoStakePool && this.userStaked.is('loading'),
          },
        ].filter((val) => val)
      },
      border() {
        return this.rowspan ? '' : 'border-0'
      },
    },
    created() {
      this.fetchAprs()
      if (this.hasNoStakePool) {
        this.fetchMarketDetails()
      } else if (this.hasDeprecatedStakePool) {
        this.fetchUserStaked()
        this.fetchMarketDetails()
      } else {
        this.fetchTotalStaked()
      }
    },
  })
</script>
