<template>
  <div v-if="combinedView">
    <pp-section :header="`YT and OT ${isMainnet ? 'Liquidity Pools' : 'Staking'}`">
      <template v-slot:right>
        <TableViewToggle
          :combined="combinedView"
          @toggle="combinedView = !combinedView"
        />
      </template>
      <StakingPoolTable :pools="combinedStackingPools" with-expiry combined />
    </pp-section>
    <ExpiredPoolSection title="Expired Pools" :pools="combinedExpiredStackingPools" combined />
  </div>

  <div v-else>
    <pp-section :header="isMainnet ? 'YT Liquidity Pools' : 'YT Staking'">
      <template v-slot:right>
        <TableViewToggle
          :combined="combinedView"
          @toggle="combinedView = !combinedView"
        />
      </template>
      <StakingPoolTable :pools="ytStakingPools" with-expiry />
    </pp-section>
    <ExpiredPoolSection title="Expired YT Pools" :pools="expiredYtStakingPools" />

    <pp-section :header="isMainnet ? 'OT Liquidity Pools' : 'OT Staking'" class="mt-10">
      <StakingPoolTable :pools="otStakingPools" with-expiry />
    </pp-section>
    <ExpiredPoolSection title="Expired OT Pools" :pools="expiredOtStakingPools" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import StakingPoolTable from '@/components/tables/staking-pools/StakingPoolTable.vue'
  import TableViewToggle from '@/components/TableViewToggle.vue'
  import StakingPool from '@/domains/entities/StakingPool'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
  import OtLpToken from '@/domains/entities/OtLpToken'
  import { sdkChainId } from '@/app-config/network'
  import ExpiredPoolSection from './ExpiredPoolSection.vue'

  export default Vue.extend({
    components: {
      StakingPoolTable,
      TableViewToggle,
      ExpiredPoolSection,
    },
    data() {
      return {
        combinedView: false,
        otStakingPools: this.getOtStakingPools(),
        ytStakingPools: this.getYtStakingPools(),
        expiredOtStakingPools: this.getExpiredOtStakingPools(),
        expiredYtStakingPools: this.getExpiredYtStakingPools(),
      }
    },
    computed: {
      combinedStackingPools() {
        return [
          ...this.otStakingPools,
          ...this.ytStakingPools,
        ]
      },
      combinedExpiredStackingPools() {
        return [
          ...this.expiredOtStakingPools,
          ...this.expiredYtStakingPools,
        ]
      },
      isMainnet() {
        return sdkChainId === 1
      },
    },
    methods: {
      getYtStakingPools() {
        return StakingPool.query()
          .where('inputToken.address', PendleAmmMarket.query().map((token) => token.address))
          .where('isActive', true)
          .where('isExpired', false)
          .where('stakableProtocol', (val) => val.includes('pendle'))
      },
      getOtStakingPools() {
        return StakingPool.query()
          .where('inputToken.address', OtLpToken.query().map((token) => token.address))
          .where('isActive', true)
          .where('isExpired', false)
          .where('stakableProtocol', (val) => val.includes('pendle'))
      },
      getExpiredYtStakingPools() {
        return StakingPool.query()
          .where('inputToken.address', PendleAmmMarket.query().map((token) => token.address))
          .where('isActive', true)
          .where('isExpired', true)
          .where('stakableProtocol', (val) => val.includes('pendle'))
      },
      getExpiredOtStakingPools() {
        return StakingPool.query()
          .where('inputToken.address', OtLpToken.query().map((token) => token.address))
          .where('isActive', true)
          .where('isExpired', true)
          .where('stakableProtocol', (val) => val.includes('pendle'))
      },
    }
  })
</script>
