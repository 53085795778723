<template>
  <pp-card class="shadow-pendle overflow-x-auto" :class="expired && 'bg-water-100'">
    <pp-table class="rounded-xl min-w-[750px]">
      <thead>
        <tr>
          <pp-th>Pool</pp-th>
          <pp-th v-if="withExpiry" align="right">Expiry</pp-th>
          <pp-th align="right">{{ isMainnet && withExpiry ? 'Total Liquidity' : 'Total Staked'}}</pp-th>
          <pp-th align="right">{{ withExpiry ? 'LP APR' : 'APR' }}</pp-th>
          <pp-th filler class="hidden lg:table-cell"></pp-th>
        </tr>
      </thead>

      <tbody>
        <StakingPoolTableItem
          v-for="poolMeta in formattedPools"
          :key="`${poolMeta.stakingPool.address}-${poolMeta.stakingPool.inputToken.address}`"
          :pool="poolMeta.stakingPool"
          :rowspan="poolMeta.rowspan"
          :combined="combined"
          :with-expiry="withExpiry"
          :data-test="`${poolMeta.stakingPool.inputToken.address.toLowerCase()}-liquidity-pool`"
        />
      </tbody>
    </pp-table>
  </pp-card>
</template>

<script>
  import Vue from 'vue'
  import StakingPoolTableItem from './StakingPoolTableItem.vue'
  import { getFormattedPools } from './formatter'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    components: { StakingPoolTableItem },
    props: {
      withExpiry: { type: Boolean, default: false },
      pools: { type: Array, default: () => [] },
      combined: { type: Boolean, default: false },
      expired: { type: Boolean, default: false },
    },
    computed: {
      formattedPools() {
        return getFormattedPools(this.pools, this.combined)
      },
      isMainnet() {
        return sdkChainId === 1
      },
    },
  })
</script>
